import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import * as Sentry from "@sentry/remix";

Sentry.init({
	dsn: window.ENV.SENTRY_DSN,
	environment: window.ENV.ENVIRONMENT,
});

function hydrate() {
	if (typeof window !== 'undefined') {
		// Ensure we're in browser environment
		const hydrateFunc = () => {
			startTransition(() => {
				hydrateRoot(
					document,
					<StrictMode>
						<RemixBrowser />
					</StrictMode>
				);
			});
		};

		if (window.requestIdleCallback) {
			window.requestIdleCallback(hydrateFunc);
		} else {
			// Fallback for browsers that don't support requestIdleCallback
			setTimeout(hydrateFunc, 1);
		}
	}
}

// Defer hydration until browser is idle
if (window.requestIdleCallback) {
	window.requestIdleCallback(hydrate);
} else {
	// Safari fallback
	window.setTimeout(hydrate, 1);
}
